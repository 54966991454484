export type DealsAsset = {
  dealsDesktop: string;
  dealsMweb: string;
  homeDesktop: string;
  homeMweb: string;
};

export type CreativeAsset = {
  [key: string]: DealsAsset;
};

/*
  // MARKET: UK, ES, IT, NL, DE, FR, JP, IN, SG, AU, KR, US, CA, BR, MX
  // LOCALE: 'en-GB', 'es-ES', 'en-US', 'it-IT', 'nl-NL', 'de-DE', 'fr-FR', 'ko-KR', 'pt-BR', 'es-MX'
  'en-US': ['CA', 'US'],
  'en-GB': ['UK', 'IN', 'SG', 'AU'],
  'en-ES': ['ES'],
  'it-IT': ['IT'],
  'nl-NL': ['NL'],
  'de-DE': ['DE'],
  'fr-FR': ['FR'],
  'ja-JP': ['JP'],
  'ko-KR': ['KR'],
  'pt-BR': ['BR'],
  'es-MX': ['MX'],
*/

const CREATIVE_ASSET: CreativeAsset = {
  'en-UK': {
    dealsDesktop:
      'https://content.skyscnr.com/m/49349933d411d3c6/original/HotelsDeals_LandingPage_Desktop_1512x1476_EMEA.jpg?imbypass=true',
    dealsMweb:
      'https://content.skyscnr.com/m/65a17576a5feab29/original/HotelsDeals_LandingPage_Mweb_375x672_EMEA.jpg?imbypass=true',
    homeDesktop:
      'https://content.skyscnr.com/m/51af5dc2109230c1/original/HotelDeals_GraphicPromo_2096x800_Desktop_35-_EN-UK.jpg?imbypass=true',
    homeMweb:
      'https://content.skyscnr.com/m/79cc2d7873b8be4e/original/HotelDeals_GraphicPromo_1200x1600_App_35-_EN-UK.jpg?imbypass=true',
  },
  'en-GB': {
    dealsDesktop:
      'https://content.skyscnr.com/m/605367a80055b781/original/HotelsDeals_LandingPage_Desktop_1512x1476_APAC.jpg?imbypass=true',
    dealsMweb:
      'https://content.skyscnr.com/m/73479d9bbc3b85f1/original/HotelsDeals_LandingPage_Mweb_375x672_APAC.jpg?imbypass=true',
    homeDesktop:
      'https://content.skyscnr.com/m/46be5dcf8d17159e/original/HotelDeals_GraphicPromo_2096x800_Desktop_35-_en_IN-SIN-AU.jpg?imbypass=true',
    homeMweb:
      'https://content.skyscnr.com/m/42b81d4e25986c29/original/HotelDeals_GraphicPromo_1200x1600_App_35-_en_IN-SIN-AU.jpg?imbypass=true',
  },
  'es-ES': {
    dealsDesktop:
      'https://content.skyscnr.com/m/49349933d411d3c6/original/HotelsDeals_LandingPage_Desktop_1512x1476_EMEA.jpg?imbypass=true',
    dealsMweb:
      'https://content.skyscnr.com/m/65a17576a5feab29/original/HotelsDeals_LandingPage_Mweb_375x672_EMEA.jpg?imbypass=true',
    homeDesktop:
      'https://content.skyscnr.com/m/7a02fdef1fd0cef0/original/HotelDeals_GraphicPromo_2096x800_Desktop_35-_ES.jpg?imbypass=true',
    homeMweb:
      'https://content.skyscnr.com/m/5563c7f54a8f89fc/original/HotelDeals_GraphicPromo_1200x1600_App_35-_ES.jpg?imbypass=true',
  },
  'it-IT': {
    dealsDesktop:
      'https://content.skyscnr.com/m/49349933d411d3c6/original/HotelsDeals_LandingPage_Desktop_1512x1476_EMEA.jpg?imbypass=true',
    dealsMweb:
      'https://content.skyscnr.com/m/65a17576a5feab29/original/HotelsDeals_LandingPage_Mweb_375x672_EMEA.jpg?imbypass=true',
    homeDesktop:
      'https://content.skyscnr.com/m/14464fae20a94413/original/HotelDeals_GraphicPromo_2096x800_Desktop_35-_IT.jpg?imbypass=true',
    homeMweb:
      'https://content.skyscnr.com/m/1a7ed3404bdea44/original/HotelsDeals_HP_Hero_Mobile_720x1000_35-_IT.png?imbypass=true',
  },
  'nl-NL': {
    dealsDesktop:
      'https://content.skyscnr.com/m/49349933d411d3c6/original/HotelsDeals_LandingPage_Desktop_1512x1476_EMEA.jpg?imbypass=true',
    dealsMweb:
      'https://content.skyscnr.com/m/65a17576a5feab29/original/HotelsDeals_LandingPage_Mweb_375x672_EMEA.jpg?imbypass=true',
    homeDesktop:
      'https://content.skyscnr.com/m/3bc43f84433fd0f0/original/HotelDeals_GraphicPromo_2096x800_Desktop_35-_NL.jpg?imbypass=true',
    homeMweb:
      'https://content.skyscnr.com/m/5bed00630e439f2d/original/HotelDeals_GraphicPromo_1200x1600_App_35-_NL.jpg?imbypass=true',
  },
  'de-DE': {
    dealsDesktop:
      'https://content.skyscnr.com/m/49349933d411d3c6/original/HotelsDeals_LandingPage_Desktop_1512x1476_EMEA.jpg?imbypass=true',
    dealsMweb:
      'https://content.skyscnr.com/m/65a17576a5feab29/original/HotelsDeals_LandingPage_Mweb_375x672_EMEA.jpg?imbypass=true',
    homeDesktop:
      'https://content.skyscnr.com/m/3f62f674bb84d9cf/original/HotelDeals_GraphicPromo_2096x800_Desktop_35-_DE.jpg?imbypass=true',
    homeMweb:
      'https://content.skyscnr.com/m/29ed7713d749459/original/HotelDeals_GraphicPromo_1200x1600_App_35-_DE.jpg?imbypass=true',
  },
  'fr-FR': {
    dealsDesktop:
      'https://content.skyscnr.com/m/49349933d411d3c6/original/HotelsDeals_LandingPage_Desktop_1512x1476_EMEA.jpg?imbypass=true',
    dealsMweb:
      'https://content.skyscnr.com/m/65a17576a5feab29/original/HotelsDeals_LandingPage_Mweb_375x672_EMEA.jpg?imbypass=true',
    homeDesktop:
      'https://content.skyscnr.com/m/c7f3987029cc869/original/HotelDeals_GraphicPromo_2096x800_Desktop_35-_FR.jpg?imbypass=true',
    homeMweb:
      'https://content.skyscnr.com/m/7512591a20b69d14/original/HotelDeals_GraphicPromo_1200x1600_App_35-_FR.jpg?imbypass=true',
  },
  'ja-JP': {
    dealsDesktop:
      'https://content.skyscnr.com/m/605367a80055b781/original/HotelsDeals_LandingPage_Desktop_1512x1476_APAC.jpg?imbypass=true',
    dealsMweb:
      'https://content.skyscnr.com/m/73479d9bbc3b85f1/original/HotelsDeals_LandingPage_Mweb_375x672_APAC.jpg?imbypass=true',
    homeDesktop:
      'https://content.skyscnr.com/m/481d390ebe2bb8d0/original/HotelDeals_GraphicPromo_2096x800_Desktop_35-_JP.jpg?imbypass=true',
    homeMweb:
      'https://content.skyscnr.com/m/29d66253af5528fd/original/HotelDeals_GraphicPromo_1200x1600_App_35-_JP.jpg?imbypass=true',
  },
  'ko-KR': {
    dealsDesktop:
      'https://content.skyscnr.com/m/605367a80055b781/original/HotelsDeals_LandingPage_Desktop_1512x1476_APAC.jpg?imbypass=true',
    dealsMweb:
      'https://content.skyscnr.com/m/73479d9bbc3b85f1/original/HotelsDeals_LandingPage_Mweb_375x672_APAC.jpg?imbypass=true',
    homeDesktop:
      'https://content.skyscnr.com/m/5dd7a0f039cda358/original/HotelDeals_GraphicPromo_2096x800_Desktop_35-_KR.jpg?imbypass=true',
    homeMweb:
      'https://content.skyscnr.com/m/6c16a4d2634bb50f/original/HotelDeals_GraphicPromo_1200x1600_App_35-_KR.jpg?imbypass=true',
  },
  'en-US': {
    dealsDesktop:
      'https://content.skyscnr.com/m/1927951004e39579/original/HotelsDeals_LandingPage_Desktop_1512x1476_AMER.jpg?imbypass=true',
    dealsMweb:
      'https://content.skyscnr.com/m/53bbc3810a48630b/original/HotelsDeals_LandingPage_Mweb_375x672_AMER.jpg?imbypass=true',
    homeDesktop:
      'https://content.skyscnr.com/m/2540d8add854c94/original/HotelDeals_GraphicPromo_2096x800_Desktop_35-_EN-US-CA.jpg?imbypass=true',
    homeMweb:
      'https://content.skyscnr.com/m/7fd82cdfdc57cd5d/original/HotelDeals_GraphicPromo_1200x1600_App_35-_EN-US-CA.jpg?imbypass=true',
  },
  'es-MX': {
    dealsDesktop:
      'https://content.skyscnr.com/m/1927951004e39579/original/HotelsDeals_LandingPage_Desktop_1512x1476_AMER.jpg?imbypass=true',
    dealsMweb:
      'https://content.skyscnr.com/m/53bbc3810a48630b/original/HotelsDeals_LandingPage_Mweb_375x672_AMER.jpg?imbypass=true',
    homeDesktop:
      'https://content.skyscnr.com/m/2aab94d1b0d984cd/original/HotelDeals_GraphicPromo_2096x800_Desktop_35-_MX.jpg?imbypass=true',
    homeMweb:
      'https://content.skyscnr.com/m/7edac3abe1f2196f/original/HotelDeals_GraphicPromo_1200x1600_App_35-_MX.jpg?imbypass=true',
  },
  'pt-BR': {
    dealsDesktop:
      'https://content.skyscnr.com/m/1927951004e39579/original/HotelsDeals_LandingPage_Desktop_1512x1476_AMER.jpg?imbypass=true',
    dealsMweb:
      'https://content.skyscnr.com/m/53bbc3810a48630b/original/HotelsDeals_LandingPage_Mweb_375x672_AMER.jpg?imbypass=true',
    homeDesktop:
      'https://content.skyscnr.com/m/66dd5b30454b8ad2/original/HotelDeals_GraphicPromo_2096x800_Desktop_35-_BR.jpg?imbypass=true',
    homeMweb:
      'https://content.skyscnr.com/m/51c32c297690f4d1/original/HotelDeals_GraphicPromo_1200x1600_App_35-_BR.jpg?imbypass=true',
  },
  UNSET: {
    dealsDesktop:
      'https://content.skyscnr.com/m/49349933d411d3c6/original/HotelsDeals_LandingPage_Desktop_1512x1476_EMEA.jpg?imbypass=true',
    dealsMweb:
      'https://content.skyscnr.com/m/65a17576a5feab29/original/HotelsDeals_LandingPage_Mweb_375x672_EMEA.jpg?imbypass=true',
    homeDesktop:
      'https://content.skyscnr.com/m/63977162d1971615/original/HotelDeals_GraphicPromo_1442x800_Tablet_EMEA-NoCopy.jpg?imbypass=true',
    homeMweb:
      'https://content.skyscnr.com/m/b0fd64fbe3e27a8/original/HotelDeals_GraphicPromo_1200x1600_App_EMEA-NoCopy.jpg?imbypass=true',
  },
};

export type PartialDealsAsset = Partial<DealsAsset>;

export type CreativeAssetBlackFriday = {
  [key: string]: PartialDealsAsset;
};

const CREATIVE_ASSET_BLACK_FRIDAY_LANDING_PAGE: CreativeAssetBlackFriday = {
  UNSET: {
    dealsDesktop:
      'https://content.skyscnr.com/m/566a305d80b8ace/original/BlackFridayHotels_LandingPage_Desktop_1512x1476.jpg?imbypass=true',
    dealsMweb:
      'https://content.skyscnr.com/m/21f30873ae3e41a5/original/BlackFridayHotels_LandingPage_Mweb_375x672.jpg?imbypass=true',
  },
};

/*
  // LOCALE: ko-KR,th-TH,zh-TW,ja-JP,pt-BR,de-DE,el-GR,EN-UK,it-IT,en-ES,en-US
  'ko-KR': ['KR'],
  'th-TH': ['TH'],
  'zh-TW': ['TW'],
  'ja-JP': ['JP'],
  'pt-BR': ['BR'],
  'de-DE': ['DE'],
  'el-GR': ['GR'],
  'EN-UK': ['UK'],
  'it-IT': ['IT'],
  'en-ES': ['ES'],
  'en-US': ['US'],
*/
const CREATIVE_ASSET_BLACK_FRIDAY_HOME_PAGE: CreativeAssetBlackFriday = {
  'ko-KR': {
    homeDesktop:
      'https://content.skyscnr.com/m/261c11e297b8cf4d/original/BlackFridayHotels_GraphicPromo_2096x800_Desktop_ko-KR.png?imbypass=true',
    homeMweb:
      'https://content.skyscnr.com/m/5331436889f06f35/original/BlackFridayHotels_GraphicPromo_1200x1600_App_ko-KR.png?imbypass=true',
  },
  'th-TH': {
    homeDesktop:
      'https://content.skyscnr.com/m/6c7be77dd7179c49/original/BlackFridayHotels_GraphicPromo_2096x800_Desktop_th-TH.png?imbypass=true',
    homeMweb:
      'https://content.skyscnr.com/m/53fe98870be6f9b5/original/BlackFridayHotels_GraphicPromo_1200x1600_App_th-TH.png?imbypass=true',
  },
  'zh-TW': {
    homeDesktop:
      'https://content.skyscnr.com/m/6f4da6bf6038996e/original/BlackFridayHotels_GraphicPromo_2096x800_Desktop_zh-TW.png?imbypass=true',
    homeMweb:
      'https://content.skyscnr.com/m/7cb75cd225c694/original/BlackFridayHotels_GraphicPromo_1200x1600_App_zh-TW.png?imbypass=true',
  },
  'ja-JP': {
    homeDesktop:
      'https://content.skyscnr.com/m/c2be41826a06484/original/BlackFridayHotels_GraphicPromo_2096x800_Desktop_ja-JP.png?imbypass=true',
    homeMweb:
      'https://content.skyscnr.com/m/2d1ccf28f66c0b0a/original/BlackFridayHotels_GraphicPromo_1200x1600_App_ja-JP.png?imbypass=true',
  },
  'pt-BR': {
    homeDesktop:
      'https://content.skyscnr.com/m/57a5519f0410563e/original/BlackFridayHotels_GraphicPromo_2096x800_Desktop_pt-BR.png?imbypass=true',
    homeMweb:
      'https://content.skyscnr.com/m/1ee08655d2c63138/original/BlackFridayHotels_GraphicPromo_1200x1600_App_pt-BR.png?imbypass=true',
  },
  'de-DE': {
    homeDesktop:
      'https://content.skyscnr.com/m/61b8eaa8d4968087/original/BlackFridayHotels_GraphicPromo_2096x800_Desktop_de-DE.png?imbypass=true',
    homeMweb:
      'https://content.skyscnr.com/m/23fcb1b05fe82fca/original/BlackFridayHotels_GraphicPromo_1200x1600_App_de-DE.png?imbypass=true',
  },
  'el-GR': {
    homeDesktop:
      'https://content.skyscnr.com/m/7986cc1ef3486843/original/BlackFridayHotels_GraphicPromo_2096x800_Desktop_el-GR.png?imbypass=true',
    homeMweb:
      'https://content.skyscnr.com/m/2681c9a47df7c726/original/BlackFridayHotels_GraphicPromo_1200x1600_App_el-GR.png?imbypass=true',
  },
  'EN-UK': {
    homeDesktop:
      'https://content.skyscnr.com/m/125b4f636b0704c/original/BlackFridayHotels_GraphicPromo_2096x800_Desktop_EN-UK.png?imbypass=true',
    homeMweb:
      'https://content.skyscnr.com/m/1efd34b3e63d87bc/original/BlackFridayHotels_GraphicPromo_1200x1600_App_EN-UK.png?imbypass=true',
  },
  'it-IT': {
    homeDesktop:
      'https://content.skyscnr.com/m/6876f9e60e07a3be/original/BlackFridayHotels_GraphicPromo_2096x800_Desktop_it-IT.png?imbypass=true',
    homeMweb:
      'https://content.skyscnr.com/m/6bfdf504a97be634/original/BlackFridayHotels_GraphicPromo_1200x1600_App_it-IT.png?imbypass=true',
  },
  'es-ES': {
    homeDesktop:
      'https://content.skyscnr.com/m/54ed818d54b05972/original/BlackFridayHotels_GraphicPromo_2096x800_Desktop_es-ES.png?imbypass=true',
    homeMweb:
      'https://content.skyscnr.com/m/666ece8b5fc6c78e/original/BlackFridayHotels_GraphicPromo_1200x1600_App_es-ES.png?imbypass=true',
  },
  'en-US': {
    homeDesktop:
      'https://content.skyscnr.com/m/44e4cb5b01d45193/original/BlackFridayHotels_GraphicPromo_2096x800_Desktop_en-US.png?imbypass=true',
    homeMweb:
      'https://content.skyscnr.com/m/577274e575b79a80/original/BlackFridayHotels_GraphicPromo_1200x1600_App_en-US.png?imbypass=true',
  },
  'fr-FR': {
    homeDesktop:
      'https://content.skyscnr.com/m/548912707b6c2588/original/BlackFridayHotels_GraphicPromo_2096x800_Desktop_fr-FR.png?imbypass=true',
    homeMweb:
      'https://content.skyscnr.com/m/42341e7b5b036949/original/BlackFridayHotels_GraphicPromo_1200x1600_App_fr-FR.png?imbypass=true',
  },
  'pt-PT': {
    homeDesktop:
      'https://content.skyscnr.com/m/f0b41b58b19782c/original/BlackFridayHotels_GraphicPromo_2096x800_Desktop_pt-PT.png?imbypass=true',
    homeMweb:
      'https://content.skyscnr.com/m/7bfa06806c67bee5/original/BlackFridayHotels_GraphicPromo_1200x1600_App_pt-PT.png?imbypass=true',
  },
  'pl-PL': {
    homeDesktop:
      'https://content.skyscnr.com/m/83eae0d6e69b8bb/original/BlackFridayHotels_GraphicPromo_2096x800_Desktop_pl-PL.png?imbypass=true',
    homeMweb:
      'https://content.skyscnr.com/m/532705532275701c/original/BlackFridayHotels_GraphicPromo_1200x1600_App_pl-PL.png?imbypass=true',
  },
  'nl-NL': {
    homeDesktop:
      'https://content.skyscnr.com/m/51ce1b9890c1500c/original/BlackFridayHotels_GraphicPromo_2096x800_Desktop_nl-NL.png?imbypass=true',
    homeMweb:
      'https://content.skyscnr.com/m/7bf015fa73345734/original/BlackFridayHotels_GraphicPromo_1200x1600_App_nl-NL.png?imbypass=true',
  },
  'tr-TR': {
    homeDesktop:
      'https://content.skyscnr.com/m/77cf02732e96a51/original/BlackFridayHotels_GraphicPromo_2096x800_Desktop_tr-TR.png?imbypass=true',
    homeMweb:
      'https://content.skyscnr.com/m/7828f77f446da07b/original/BlackFridayHotels_GraphicPromo_1200x1600_App_tr-TR.png?imbypass=true',
  },
  'es-MX': {
    homeDesktop:
      'https://content.skyscnr.com/m/3fa66d1f28916954/original/BlackFridayHotels_GraphicPromo_2096x800_Desktop_es-MX.png?imbypass=true',
    homeMweb:
      'https://content.skyscnr.com/m/666ece8b5fc6c78e/original/BlackFridayHotels_GraphicPromo_1200x1600_App_es-ES.png?imbypass=true',
  },
  UNSET: {
    homeDesktop:
      'https://content.skyscnr.com/m/125b4f636b0704c/original/BlackFridayHotels_GraphicPromo_2096x800_Desktop_EN-UK.png?imbypass=true',
    homeMweb:
      'https://content.skyscnr.com/m/1efd34b3e63d87bc/original/BlackFridayHotels_GraphicPromo_1200x1600_App_EN-UK.png?imbypass=true',
  },
};

export default CREATIVE_ASSET;
export {
  CREATIVE_ASSET_BLACK_FRIDAY_LANDING_PAGE,
  CREATIVE_ASSET_BLACK_FRIDAY_HOME_PAGE,
};
