import BpkImage, {
  withLazyLoading,
  withLoadingBehavior,
} from '@skyscanner/backpack-web/bpk-component-image';

export const documentIfExists = global.window ? global.window.document : null;

export const FadingLazyLoadedImage = withLoadingBehavior(
  withLazyLoading(BpkImage, documentIfExists),
);
FadingLazyLoadedImage.displayName = 'FadingLazyLoadedImage';

export const LoadingImage = withLoadingBehavior(BpkImage);
LoadingImage.displayName = 'LoadingImage';

export const LazyLoadedImage = withLazyLoading(BpkImage, documentIfExists);
LazyLoadedImage.displayName = 'LazyLoadedImage';
